@import 'style/variables.scss';

.detail {
  min-height: 170px; // it is always this high
  width: 60%;
  box-sizing: border-box;
}

.title {
  font-weight: bold;
  padding: $paddingSmall $paddingXXLarge $paddingSmall 0;
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .detail {
    width: auto;
  }
}
