@import 'style/variables.scss';

.TopBar {
  display: flex;
  flex-direction: column;
}

.mainTopBar {
  background-color: $colorPrimary;
  color: $colorTextOnBackground;
  height: $heightTopBar;
  width: 100%;
  display: flex;
  box-shadow: 0px -15px 20px 5px $colorBlack;
  z-index: 1;
}

.logo {
  height: 100%;
  padding: $paddingDefault;
  padding-right: calc(2 * #{$paddingDefault});
  box-sizing: border-box;
  min-width: 236px; // when logo is loading other elements are jumping around to fill free space
  cursor: pointer;
}

.title {
  font-weight: bold;
  font-size: $fontSizeSmall;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.conductTitle {
  @extend .title;
}

.impersonatedName {
  @extend .title;
  @extend .button;
  background-color: $colorActive;
  text-decoration: none;
  position: relative;
}

.docBadge {
  position: absolute;
  top: 10px;
  left: 0;
  width: 20px;
  height: 20px;
  background: #ee2f2f;
  text-align: center;
  border-radius: 50%;
  color: white;
  z-index: 0;
}

.link {
  text-decoration: none;
}

.iconHome {
  font-size: 24px;
}

.iconLogout {
  font-size: 20px;
}

.button {
  background-color: $colorActive;
  color: $colorTextOnBackground;
  height: $heightTopBar;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
}

.titleButton {
  @extend .button;
  width: $heightTopBar;
}

.tenantSelect {
  margin-left: auto;
  display: flex;
  align-items: center;
  padding: 20px;
}

.tenantTitle {
  padding-right: 5px;
  font-size: $fontSizeSmall;
  color: $colorBackground;
}

.tenantMobileTitle {
  font-size: $fontSizeSmall;
  color: $colorBackground;
  padding-bottom: 5px;
}

.selectWrapper {
  min-width: 270px;
  box-sizing: border-box;
}

.mobileSelectWrapper {
  width: 100%;
  box-shadow: none;
}

.logoutButton {
  @extend .button;
  font-size: $fontSizeSmall;
  font-weight: bold;
  padding: 20px;
  white-space: nowrap;
}

.contactButton {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #6CC50C;
  color: $colorTextOnBackground;
  margin: 10px;
  padding: 20px;
  transition: background-color 200ms;

  &:hover {
    background-color: #6CC50Cee;
    transition: background-color 200ms;
  }
}

.marginLeftAuto {
  margin-left: auto;
  display: flex;
}

.mobileViewBar {
  display: flex;
  flex-direction: column;
  background-color: $colorPrimary;
  padding: $paddingLarge;
}

.documentsTable {
  color: $colorBlack;
}

.alreadyPicked {
  padding-top: 20px;
  text-align: center;
  color: $colorPrimary;
  font-size: 18px;
}
.noDocumentsToPick {
  font-size: 14px;
  color: $colorTextGray;
  text-align: center;
}
.notPickedDocuments {
  font-size: 14px
}
.alreadyPickedDocuments {
  color: $colorText;
  font-size: 14px
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .mainTopBar {
    height: $mobileViewHeightTopBar;
  }

  .titleButton, .logoutButton {
    height: $mobileViewHeightTopBar;
  }

  .impersonatedName {
    height: $mobileViewHeightTopBar;
    padding: $paddingSmall
  }

  .logo {
    min-width: auto;
    padding-right: 10px;
  }

  .button {
    background: transparent;
  }

  .conductTitle {
    padding: $paddingLarge
  }

  .docBadge {
    left: -15px;
  }
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .titleButton, .logoutButton {
    width: $mobileViewHeightTopBar;
  }

  .contactButton {
    padding: 12px;
    font-size: 14px;
  }

  .logo {
    width: 60px;
  }
}
