@import 'style/variables.scss';

.HomePage {
  padding: $paddingDefault;
  max-width: $widthContent;
  width: 100%;
  height: calc(100% - #{$heightTopBar} - #{$heightFooter});
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}

.greeting {
  font-size: $fontSizeTitle;
  color: $colorPrimary;
  font-weight: bold;
  padding: $paddingDefault;
  text-align: center;
}

.iconHotel {
  font-size: 36px;
  color: $colorPrimary;
}

.greetingDivider {
  width: 100px;
  height: 1px;
  margin: $paddingSmall;
  border-top: 2px solid $colorBackgroundGray;
}

.select {
  width: 100%;
  padding: $paddingLarge;
  margin: $paddingXXLarge;
  font-weight: bold;
  border: none;
  composes: boxShadow from 'style/effects.scss';
}

.button {
  text-transform: uppercase;
  color: $colorTextOnBackground;
  background-color: $colorPrimary;
  padding: $paddingDefault $paddingLarge;
  cursor: pointer;
  border: none;
  margin: $paddingDefault;
}

.buttonEnter {
  @extend .button;
  background-color: $colorPrimary;
}

.buttonCreateImpersonation {
  @extend .button;
  background-color: $colorSignalGreen;
}

.buttonDestroyImpersonation {
  @extend .button;
  background-color: $colorSignalOrange;
}

.impersonatedName {
  font-weight: bold;
  display: inline;
}

.oSpolecnosti,
.najemniPohadka {
  padding: $paddingXLarge;
  box-sizing: border-box;
  text-align: center;
}

.najemniPohadka {
  position: relative;
  padding-bottom: 56.25%;
}

.najemniPohadkaIFrame {
  padding: $paddingXLarge;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.centerText {
  text-align: center;
}

.selectWrapper {
  box-sizing: border-box;
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .selectWrapper {
    padding: 0 $paddingDefault;
  }
  .oSpolecnosti {
    padding: $paddingSmall;
  }
}
