@import 'style/variables.scss';

.contacts {
  display: flex;
  flex-direction: column;
  position: relative;
  width: $widthContent;
  margin: 0 auto;
}

.tableHeaderRow {
  display: flex;
  flex-direction: row;
  text-align: left;
  background-color: $colorBackgroundGray;
  font-weight: normal;
  white-space: nowrap;

  div {
    padding: $paddingDefault;
    width: 22%;
  }
}

.table {
  .tableRow {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $colorBackgroundGray;
    min-width: fit-content;

    &:last-child {
      border-bottom: none;
    }

    div {
      padding: 10px;
      width: 22%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.contactCell {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.contractStatusCell {
  width: 14% !important;
  display: flex;
  align-items: center;

  .contractStatus {
    display: flex;
    justify-content: center;
    padding: 5px;
    color: white;
    font-weight: 500;
    width: 100%
  }

  .green {
    background: #6CC50C;
  }
  .gray {
    background: $colorTextGray;
  }
  .orange {
    background: #FFAE00;
  }
}

.filterField {
  color: $colorText;
  padding: 0 10px;
  background-color: white;
}

.contractTypeCell {
  width: 18% !important;
}
