@import 'style/variables.scss';

.overview {
  display: -webkit-box;   /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
  flex: 1 0 auto;
  flex-direction: row;
  position: relative;
  width: $widthContent;
  margin: 0 auto;
  justify-content: flex-end;
  overflow: visible;
}

.phoneImgContainer {
  position: absolute;
  top: -50px;
  left: -65px;
  width: 260px;
  transform: rotate(-5deg);
  pointer-events: none;

  span {
    width: 402px;
    position: absolute;
    top: 50px;
    right: calc(50% - 201px);
    z-index: 1000;
    text-align: center;
    padding: 0 130px;
    box-sizing: border-box;
    font-size: 22px;
    font-weight: 900;
    color: $colorText;

    .phoneNumber {
      font-size: 70px;
      height: 70px;
      margin-top: 30px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .daysText {
    font-size: 20px;
    font-weight: 900;
    color: $colorText;
    margin-bottom: 30px;
  }

  .sosButton {
    background: #E83B3B;
    color: #f9f9f9;
    font-size: 11px;
    font-weight: 500;
    margin: 0 auto;
    padding: 10px 0;
    width: 140px;
    cursor: pointer;
    pointer-events: auto;
    transition: color 200ms, background 200ms;
    border-radius: 5px;

    &:hover {
      color: #ffffff;
      background: #E83B3Bee;
      transition: color 200ms, background 200ms;
    }
  }

  .sosText {
    font-size:13px;
  }

  .sosSubText {
    font-size: 10px;
  }

  .sosDisabled {
    background: lightgray;
    color: $colorText;
    font-size: 11px;
    font-weight: 500;
    margin: 0 auto;
    padding: 5px 0;
    width: 120px;
    border-radius: 5px;
  }

  img {
    z-index: 1001;
    width: 260px;
  }
}

.numberBoxGreen {
  background: $colorBackgroundGray;
  border-radius: 22px;
  padding: 12px;
  margin: 10px;
  max-width: 188px;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  position: relative;

  .numberBoxTextGreen {
    color: $colorText;
    font-weight: 700;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid #6CC50Ccc;
    font-size: 13px;
  }

  .numberBoxNumber {
    background: white;
    width: 164px;
    height: 164px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 100px;
    font-weight: 900;
    color: #6CC50Ccc;
    padding-bottom: 25px;
    border-radius: 14px;
  }

  .clickIcon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 24px;
    height: 24px;

    svg {
      fill: #909090cc
    }
  }
}

.numberBox {
  background: $colorBackgroundGray;
  border-radius: 22px;
  padding: 12px;
  margin: 10px;
  max-width: 188px;
  box-sizing: border-box;
  text-align: center;

  .numberBoxTextRed {
    color: $colorText;
    font-weight: 700;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid #E83B3Bcc;
    font-size: 13px;
  }

  .numberBoxPercent {
    background: white;
    width: 164px;
    height: 164px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    font-weight: 900;
    color: #000000bb;
    padding-bottom: 15px;
    border-radius: 14px;

    span {
      font-size: 50px;
      position: relative;
      top: 10px;
    }
  }
}

.table {
  overflow: auto;
}

.tableHeaderRow {
  display: flex;
  flex-direction: row;
  text-align: left;
  background-color: $colorBackgroundGray;
  font-weight: normal;
  white-space: nowrap;
  width: fit-content;
  min-width: 100%;

  div {
    padding: $paddingDefault;
    white-space: nowrap;
    width: 100%;
    min-width: 130px;

    &:nth-child(1) {
      min-width: 150px;
    }

  }
}
.tableRow {
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid $colorBackgroundGray;

  &:last-child {
    border-bottom: none;
  }

  div {
    padding: 10px;
    width: 33.3%;
    min-width: 130px;
    &:nth-child(1) {
      min-width: 150px;
    }
  }
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .overview {
    overflow: hidden;
    width:950px;
  }

  .phoneImgContainer {
    left: -10px;
    top: -16px;
    width: 190px;
    pointer-events: none;
    transform: rotate(0deg);

    span {
      width: 300px;
      right: calc(50% - 150px);
      padding: 0 100px;
      font-size: 14px;

      .phoneNumber {
        height: 60px;
        font-size: 55px;
        margin-top: 20px;
      }
    }

    .daysText {
      font-size: 18px;
      margin-bottom: 20px;
    }

    .sosButton {
      box-sizing: border-box;
      font-size: 8px;
      width: 110px;
      border-radius: 5px;
      left: -5px;
      position: relative;
    }

    .sosDisabled {
      background: lightgray;
      color: $colorText;
      font-size: 9px;
      font-weight: 500;
      margin: 10px auto 0;
      padding: 5px 0;
      width: 100px;
      border-radius: 5px;
    }

    img {
      z-index: 1001;
      width: 190px;
    }
  }

  .numberBoxGreen, .numberBox {
    max-width: 174px;
    & .numberBoxNumber {
      width: 150px;
    }
    & .numberBoxPercent {
      width: 150px;
      font-size: 75px;
    }
  }

  .numberBoxTextGreen, .numberBoxTextRed {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .overview {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .phoneImgContainer {
      position: relative;
      left: 0;
      top: 0;
      width: 220px;

      span {
        font-size: 14px;
      }

      img {
        width: 220px;
        height: 330px;
      }

      .phoneNumber {
        margin-top: 20px;
      }

      .daysText {
        margin-bottom: 30px;
      }

      .sosButton {
        width: 120px;
        position: relative;
        left: -10px;
        border-radius: 5px;
      }

      .sosDisabled {
        border-radius: 5px;
      }
    }
  }
}
