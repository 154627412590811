@import 'style/variables.scss';

.iconPdf {
  text-decoration: none;
  color: $colorSignalRed;
  cursor: pointer;
  font-size: 20px;
  padding: 0px !important;
}

.invoice {
  display: flex;
  flex-direction: column;
  position: relative;
  width: $widthContent;
  margin: 0 auto;
}

.tableHeaderRow {
  display: flex;
  flex-direction: row;
  text-align: left;
  background-color: $colorBackgroundGray;
  font-weight: normal;
  white-space: nowrap;

  div {
    padding: $paddingDefault;
    width: 29%;
  }
}

.table {
  .tableRow {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid $colorBackgroundGray;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    div {
      padding: 10px;
      width: 29%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.monthCell {
  text-transform: capitalize;
}

.isPaidCell {
  width: 18% !important;
  display: flex;
  align-items: center;
  & svg {
    height: 24px;
    width: 24px;
  }

  & .greenCheck {
    color: #6CC50C;
    font-size: 16px;
  }

  & .redCross {
    color: #E83B3B;
    font-size: 16px;
  }
}
