@import 'style/variables.scss';

.DetailPageMenu {
  display: flex;
  justify-content: space-between;
  width: $widthContent;
  background-color: $colorPrimary;
  margin: 0 auto;
  transform: translate(0, -50%);

  span {
    display: flex;
  }
}

.filterContainer {
  display: flex;
  align-items: center;

  select, input {
    height: 36px;
    box-sizing: border-box;
    width: 140px;
    margin-right: 11px;
    border: 1px solid #999 !important;
    border-radius: 2px;
    font-size: 14px;
    transition: border 200ms;

    &:focus-visible {
      outline: none;
    }

    &:hover {
      border: 1px solid black !important;
      transition: border 200ms;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 0 dotted black;
}

.tooltip .tooltiptext {
  opacity: 0;
  width: auto;
  background-color: #e9e9e9;
  color: #1a1a1a;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  border: 1px solid #727272;
  font-size: 10px;
  transition: opacity 200ms;

  /* Position the tooltip */
  position: absolute;
  top: -30px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  opacity: 1;
  transition: opacity 200ms;

}

.linkWrapper {
  position: relative;
}

.link {
  display: inline-block;
  background-color: $colorPrimary;
  color: $colorTextOnBackground;
  font-weight: bold;
  padding: 16px 32px;
  text-decoration: none;
}

.link.active {
  background-color: $colorActive;
}

$arrowSize: 8px;
.link.active:before {
  content: '';
  width: 0;
  height: 0;
  border-left: $arrowSize solid transparent;
  border-right: $arrowSize solid transparent;
  border-top: $arrowSize solid $colorActive;
  position: absolute;
  top: calc(100% - 1px);
  left: calc(50% - #{$arrowSize});
}

.link:hover {
  background-color: $colorActive;
}

.filterField {
  color: $colorText;
  padding: 0 10px;
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .filterContainer {
    margin: 0 10px 15px 10px;
  }
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .DetailPageMenu {
    transform: none;
  }
}
