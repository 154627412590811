@import 'style/variables.scss';

.DetailPage {
  box-sizing: border-box;
  width: 100%;
}

.topPartBackground {
  width: 100%;
  background-color: $colorBackgroundGray;
  padding: 20px 0 40px;
  box-sizing: border-box;
}

.topPart {
  margin: 0 auto;
  width: $widthContent;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: $tabletViewMaxWidth) {
  .topPartBackground {
    padding: $paddingLarge
  }

  .topPart {
    width: auto;
  }

  .scrollTable {
    overflow-x: auto;
  }
}

@media only screen and (max-width: $mobileViewMaxWidth) {
  .topPart {
    flex-direction: column;
  }
}
